import {Dropdown} from '@/components/Form';
import {Field} from 'formik';
import moment from 'moment';
import React from 'react';
import {now} from '@/lib/utils/date';
import {days, months} from '@/config/constants/options';

type Props = {
  name: string;
  range?: number[];
  onChange: (name: string, value: any) => void;
  disabled?: boolean;
};

const DateInput = ({name, range, onChange, disabled}: Props) => {
  const names = [name, `${name}_year`, `${name}_month`, `${name}_day`];
  const year = moment().format('YYYY');

  const ranges = (() => {
    const arr: number[] = [];

    const start = range && Array.isArray(range) ? range[0] : -5;
    const end = range && Array.isArray(range) ? range[1] : 0;

    if (start >= end) {
      for (let i = end; i <= start; i++) {
        arr.push(i);
      }
    } else {
      for (let i = start; i <= end; i++) {
        arr.push(i);
      }
    }

    return arr;
  })();

  return (
    <div className="b__field__row">
      {names.map((name, i) => {
        if (i === 0) return <React.Fragment key={name} />;

        return (
          <div className="b__field__column" key={name}>
            <Field
              name={name}
              component={Dropdown}
              fluid
              selection
              placeholder={(() => {
                if (i === 1) {
                  return now('YYYY');
                }
                if (i === 2) {
                  return now('M');
                }
                if (i === 3) {
                  return now('D');
                }
                return undefined;
              })()}
              options={(() => {
                if (i === 1) {
                  return [
                    {
                      key: '0',
                      value: '',
                      text: '',
                    },
                    ...ranges.map((r) => {
                      return {
                        key: `${r}`,
                        value: `${(year as any) - r * -1}`,
                        text: `${(year as any) - r * -1}`,
                      };
                    }),
                  ];
                }
                if (i === 2) {
                  return months;
                }
                if (i === 3) {
                  return days;
                }
                return [];
              })()}
              onChange={(_: any, field: any) => {
                onChange(name, field.value);
              }}
              disabled={disabled}
            />
            <span>{i === 1 ? '年' : i === 2 ? '月' : '日'}</span>
          </div>
        );
      })}
    </div>
  );
};

export default DateInput;
