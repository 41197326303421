import {mergeDate} from '@/lib/utils/date';
import paths from '@/config/paths';
import {createStocks} from '@/api/stocks';
import notify from '@/migration/notification';
import {MeatFrontToBack} from '@/modules/meat';

type StockParams = {
  area: number;
  capacity: number;
  cleaningMethod: number;
  closeBidMin: number;
  count: number;
  deliveryMethod: number;
  explanation: string;
  fishingDay: string;
  fixPrice?: number;
  imageJson: string[];
  isDummy: boolean;
  isLandAfterFix: boolean;
  repeatLimitDate: string;
  sellerUserId: number;
  shippingExpense: number;
  shippingSize: number;
  shippingTiming: number;
  size: number;
  startPrice: number;
  weight?: number;
  weightAdditionalInfo: string;

  // 追加
  name: string;
  breed: string;
  isUnevenness: boolean;
  producer: string;
  grade: number;
  origin: number;
  originAdditionalInfo: string;
  averageSugarContent: number;
  harvestDate: string;
  harvestTiming: number;
  saveMethodUser: number;
  saveMethodSeller: number;
  saveMethodUserAdditionalInfo: string;
  cultivationMethod: number;
  soil: string;
  soilAdditionalInfo: string;
  pesticides: string;
  pesticidesAdditionalInfo: string;
  genetic: number;
  jas: number;
  individualObligations: string;
  globalGap: string | null;
};

const toInt = (str: string) => {
  return str ? parseInt(str, 10) : 0;
};

const createParams = (values: any) => {
  let fishingDay = '';

  if (
    values.fishingDate.year &&
    values.fishingDate.month &&
    values.fishingDate.day
  ) {
    fishingDay = mergeDate(
      values.fishingDate.year,
      values.fishingDate.month,
      values.fishingDate.day,
      'YYYY-MM-DD'
    );
  }

  const meatValues = MeatFrontToBack(values);

  const stockParams: StockParams = {
    // 追加
    ...meatValues,

    area: values.area,
    capacity: toInt(values.capacity),
    cleaningMethod: values.cleaningMethod,
    closeBidMin: values.closeBidMin * 60,
    count: 0,
    deliveryMethod: values.deliveryMethod,
    explanation: values.explanation,
    fishingDay,
    imageJson: values.imageJson,
    isDummy: values.isDummy,
    isLandAfterFix: values.isLandAfterFix,
    repeatLimitDate: mergeDate(
      values.repeatLimitDate.year,
      values.repeatLimitDate.month,
      values.repeatLimitDate.day,
      'YYYY-MM-DD'
    ),
    // savingMethod: values.savingMethod,
    sellerUserId: values.sellerUserId,
    shippingExpense: values.shippingExpense,
    shippingSize: values.shippingSize,
    shippingTiming: values.shippingTiming,
    size: toInt(values.size),
    startPrice: toInt(values.startPrice),
    weightAdditionalInfo: values.weightAdditionalInfo,
  };

  if (values.weight) stockParams.weight = toInt(values.weight);

  if (values.fixPrice) stockParams.fixPrice = toInt(values.fixPrice);

  return {stockParams};
};

// TODO 型づけ
export const submit = async (
  value: any,
  actions: any,
  history: any,
  setModal: any
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = createParams(value);

    await createStocks(stockParams);

    notify('自動再出品登録しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    console.log(error);
    // notify(error.message, 'error');
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
