import {MEAT_CATEGORY_TYPE, MEAT_ITEMS} from '../config';
import moment from 'moment';
import 'moment/locale/ja';
import {ConversionMeatCategory} from './conversion-category';

export const MeatFrontToBack = (values: any) => {
  try {
    const category = ConversionMeatCategory(
      values['category'],
      'en'
    ) as MEAT_CATEGORY_TYPE;
    if (!category) return {...values};

    const conversionValues = (() => {
      const res = {};

      for (const k in MEAT_ITEMS) {
        const d = MEAT_ITEMS[k];

        // LinkValue
        if (d.linkValue) {
          const isValid = values[d.linkValue.name] === d.linkValue.value;
          const value = values[d.linkValue.target];

          if (isValid && value !== undefined) {
            res[k] = value;
            continue;
          }
        }

        // 数値
        if (d.type === 'int' && values[k]) {
          res[k] = parseFloat(values[k]);
        }

        // チェックボックス
        if (d.type === 'checkbox' && Array.isArray(values[k])) {
          const select = (d.select ?? [])
            .flatMap((item) =>
              item.show
                ? item.show.includes(category)
                  ? item.values
                  : []
                : item.values
            )
            .map((v) => (typeof v === 'string' ? v : v.value));

          res[k] = values[k]
            .map((check, i) => (check ? select[i] : undefined))
            .filter((f) => f !== undefined)
            .join(',');
        }

        // 日付
        if (d.type === 'date') {
          const yy = values[`${k}_year`];
          const mm = values[`${k}_month`];
          const dd = values[`${k}_day`];

          if (yy && mm && dd) {
            res[k] = moment([yy, mm - 1, dd]).format('YYYY-MM-DD');
          }
        }
      }

      return res;
    })();

    return {
      ...values,
      ...conversionValues,
    };
  } catch {
    return {};
  }
};
