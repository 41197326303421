import * as _utils from './_utils';

const prd: () => Env = () => {
  const envcode: Envcode = 'prd';

  return {
    NAME: '本番環境',
    COGNITO_REGION: 'ap-northeast-1',
    COGNITO_USER_POOL_ID: 'ap-northeast-1_sNoYSX8Pq',
    COGNITO_CLIENT_ID: 'noup2ghqt868a9sv2cse9mopd',
    COGNITO_DOMAIN: 'https://fishsale.auth.ap-northeast-1.amazoncognito.com',
    COGNITO_REDIRECT_URI: 'https://vendor.meatsale.jp/signin',
    API_GO_ENDPOINT: 'https://api.meatsale.jp',
    SITE_TYPE: 'meat',
    ENVCODE: envcode,
    TITLE: _utils.getName(envcode),
    WEB_APP_HOST: 'https://meatsale.jp',
  };
};

export default prd();
