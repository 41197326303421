export const getName = (envcode: Envcode): string => {
  let name: string = 'MeatSale' || 'unknown';

  switch (envcode) {
    case 'local': {
      name = `${name} -ローカル環境-`;
      break;
    }
    case 'dev': {
      name = `${name} -開発環境-`;
      break;
    }
    case 'stg': {
      name = `${name} -ステージ環境-`;
      break;
    }
    case 'prd': {
      name = `${name}`;
      break;
    }
  }

  return name;
};
