import React from 'react';
import {
  MEAT_CATEGORY_TYPE,
  MEAT_ITEMS,
  RAW_MESSAGE,
  RAW_MESSAGE_GAME,
  RAW_MESSAGE_PORK,
} from '../config';
import {Field} from 'formik';
import {Input, Label, Dropdown, Checkbox} from '@/components/Form';
import {IsShow} from '../utils';
import DateInput from './date-input';

interface Props {
  disabled?: boolean;
  category?: MEAT_CATEGORY_TYPE;
  formValues: Record<string, any>;
  errors: Record<string, any>;
  onChange: (name: string, value: string) => void;
}

const inputs = ({
  disabled: disabled,
  category,
  formValues,
  errors,
  onChange,
}: Props) => {
  return (
    <>
      {Object.keys(MEAT_ITEMS).map((key, i) => {
        const d = MEAT_ITEMS[key];
        const name = key;

        // 表示するかどうか
        if (!category || !IsShow(category, formValues, d)) {
          return <React.Fragment key={i}></React.Fragment>;
        }

        // 必須かどうか
        const isRequired = (() => {
          if (d.linkRequired) {
            const data = formValues[d.linkRequired.name];

            if (
              data &&
              (d.linkRequired.value === true || d.linkRequired.value === data)
            ) {
              return true;
            }
          }

          return d.required;
        })();

        // disabledかどうか
        const isDisabled = (() => {
          if (disabled) return true;

          if (d.linkDisabled) {
            const data = formValues[d.linkDisabled.name];

            if (
              data &&
              (d.linkDisabled.value === true || d.linkDisabled.value === data)
            ) {
              return true;
            }
          }

          return disabled;
        })();

        const disableHelper = (() => {
          if (d.linkDisabled) {
            const data = formValues[d.linkDisabled.name];

            if (
              data &&
              (d.linkDisabled.value === true || d.linkDisabled.value === data)
            ) {
              return d.linkDisabled.helper ?? '';
            }
          }
        })();

        // セレクト・チェックボックス用のデータ配列
        const values = d.select
          ? d.select
              .filter((f) =>
                f.show && category ? f.show.includes(category) : true
              )
              .flatMap((item) =>
                item.values.map((e) => {
                  const value = typeof e === 'string' ? e : e.value;
                  const text =
                    typeof e === 'string' ? e : e.label ? e.label : e.value;

                  return {
                    ...{
                      value,
                      text,
                    },
                  };
                })
              )
          : [];

        // ラベル
        const label = d.unit ? {basic: true, content: d.unit} : undefined;

        const placeholder = d.placeholder;

        const labelValue = (() => {
          // ラベル
          const label = (() => {
            if (category && d.labelPattern) {
              const l = d.labelPattern[category];
              if (l) return l;
            }
            return d.label;
          })();

          const validate = (() => {
            // 文字数制限 - 文字列
            if (
              (d.validate &&
                d.validate.max &&
                (d.type === 'string' || !d.type)) ||
              ((d.type === 'string' || !d.type) && !d.select)
            ) {
              return `(最大${d.validate?.max ?? `50`}文字)`;
            }

            // 文字数制限 - 数値
            if (
              (d.validate && d.validate.max && d.type === 'int') ||
              d.type === 'int'
            ) {
              return `(最大${(d.validate?.max ?? 10_000_000).toLocaleString()}${
                d.unit ?? 'g'
              })`;
            }

            return '';
          })();

          return `${label ?? ''} ${validate}`;
        })();

        return (
          <div
            className="b__field"
            key={i}
            style={{marginTop: d.label ? undefined : -14}}
          >
            {/* 真偽はラベル非表示 */}
            {d.label && (
              <Label
                text={labelValue}
                required={isRequired}
                optional={!isRequired}
              />
            )}

            {/* 各フォーム */}
            {(() => {
              if (d.type === 'string' || !d.type) {
                /**
                 * セレクトボックス
                 */
                if (d.select) {
                  const options = values;

                  if (!isRequired) {
                    options.unshift({
                      value: '',
                      text: '未選択',
                    });
                  }

                  return (
                    <Field
                      component={Dropdown}
                      selection
                      fluid
                      placeholder="選択してください"
                      labelPosition={label ? 'right' : undefined}
                      onChange={(_: any, field: any) => {
                        onChange(name, field.value);
                      }}
                      {...{name, disabled: isDisabled, options, label}}
                    />
                  );
                }

                /**
                 * テキストボックス
                 */
                return (
                  <>
                    <Field
                      component={Input.Default}
                      maxLength={50}
                      initialValue={formValues[name]}
                      onChange={(_: any, field: any) => {
                        onChange(name, field.value);
                      }}
                      {...{name, disabled: isDisabled, label, placeholder}}
                    />

                    {!d.label && labelValue && (
                      <div className="b__paragraph">{labelValue}</div>
                    )}
                  </>
                );
              }

              /**
               * 真偽のチェックボックス
               */
              if (d.type === 'bool') {
                return (
                  <Field
                    component={Checkbox}
                    label={d.checkboxLabel ?? d.label}
                    onChange={(_: any, field: any) =>
                      onChange(name, field.checked)
                    }
                    checked={formValues[key]}
                    {...{name, disabled: isDisabled}}
                  />
                );
              }

              /**
               * 複数項目のチェックボックス
               */
              if (d.type === 'checkbox') {
                if (values.length === 0) return null;

                return (
                  <div className="stack_checkbox">
                    {values.map(({text}, i) => (
                      <Field
                        name={`${name}[${i}]`}
                        component={Checkbox}
                        label={text}
                        key={i}
                        onChange={(_: any, field: any) => {
                          onChange(`${name}[${i}]`, field.value);
                        }}
                      />
                    ))}
                  </div>
                );
              }

              /**
               * 日付
               */
              if (d.type === 'date') {
                return (
                  <div className="w__date-wrapper">
                    <DateInput
                      range={d.dateRange}
                      {...{name, disabled: isDisabled, onChange}}
                    />
                  </div>
                );
              }

              /**
               * その他
               */
              return (
                <>
                  <Field
                    type="text"
                    component={Input.Default}
                    maxLength={50}
                    labelPosition={label ? 'right' : undefined}
                    onChange={(_: any, field: any) => {
                      onChange(name, field.value);
                    }}
                    {...{name, disabled: isDisabled, label, placeholder}}
                  />
                  {!d.label && labelValue && (
                    <div className="b__paragraph">{labelValue}</div>
                  )}
                </>
              );
            })()}

            {/**
             *エラーメッセージ
             */}
            {errors[name] !== undefined ? (
              <a className="form__error">{errors[name]}</a>
            ) : null}

            {/**
             * Disabled時の補助メッセージ
             */}
            {disableHelper && (
              <label
                className="flex attention b__dropzone__paragraph"
                style={{color: '#f55'}}
              >
                {disableHelper}
              </label>
            )}

            {d.helperText && (
              <label className="flex attention b__dropzone__paragraph">
                {d.helperText}
              </label>
            )}
          </div>
        );
      })}

      {/* 生食の場合は自動挿入 */}
      {formValues['purpose'] === '生食用' &&
        category !== 'pork' &&
        category !== 'game' && (
          <div className="field_wrapper" style={{marginTop: -14}}>
            <div className="field-group">
              <textarea
                style={{
                  width: '100%',
                  resize: 'none',
                  border: 'solid 1px #ccc',
                  borderRadius: '5px',
                  padding: '11px 16px',
                }}
                disabled
              >
                {RAW_MESSAGE}
              </textarea>
            </div>
          </div>
        )}

      {/* 豚肉の注意事項 */}
      {category === 'pork' && (
        <div className="field_wrapper" style={{marginTop: -14}}>
          <div className="field-group">
            <textarea
              style={{
                width: '100%',
                resize: 'none',
                border: 'solid 1px #ccc',
                borderRadius: '5px',
                padding: '11px 16px',
              }}
              disabled
            >
              {RAW_MESSAGE_PORK}
            </textarea>
          </div>
        </div>
      )}

      {/* ジビエの注意事項 */}
      {category === 'game' && (
        <div className="field_wrapper" style={{marginTop: -14}}>
          <div className="field-group">
            <textarea
              style={{
                width: '100%',
                resize: 'none',
                border: 'solid 1px #ccc',
                borderRadius: '5px',
                padding: '11px 16px',
              }}
              disabled
            >
              {RAW_MESSAGE_GAME}
            </textarea>
          </div>
        </div>
      )}
    </>
  );
};

export default inputs;
