import {MEAT_ITEMS} from '../../../modules/meat/config/index';
const MeatDefault = {};

Object.keys(MEAT_ITEMS).forEach((key) => {
  const d = MEAT_ITEMS[key];

  if (d.defaultValue) {
    MeatDefault[key] = d.defaultValue;
  }
});

export const initialState = {
  imageJson: [],
  name: '',
  size: '',
  weight: '',
  weightAdditionalInfo: '',
  fishingSpot: '',
  fishingSpotDetail: '',
  fishingDate: {
    year: '',
    month: '',
    day: '',
  },
  isLandAfterFix: false,
  isNature: true,
  ikejimeMethod: 101,
  isIkejime: false,
  ikejimeWater: 0,
  cleaningMethod: 1,
  ikejimeTiming: 0,
  savingMethod: 1,
  isVacuum: false,
  shippingExpense: 1,
  shippingSize: 60,
  deliveryMethod: '',
  shippingTiming: '',
  area: '',
  startPrice: '',
  fixPrice: '',
  closeBidMin: '',
  explanation: '',
  capacity: '',
  repeatLimitDate: {
    year: '',
    month: '',
    day: '',
  },

  // 追加
  category: '牛',
  ...MeatDefault,
};
