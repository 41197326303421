import * as _utils from './_utils';

const local: () => Env = () => {
  const envcode: Envcode = 'local';

  return {
    NAME: 'ローカル環境',
    // COGNITO_REGION: 'ap-northeast-1',
    // COGNITO_USER_POOL_ID: 'ap-northeast-1_p5AdpeGgu',
    // COGNITO_CLIENT_ID: '7r9ev1smu38gucunqik870sk8j',

    COGNITO_REGION: 'ap-northeast-1',
    COGNITO_USER_POOL_ID: 'ap-northeast-1_kTg8o2iYc',
    COGNITO_CLIENT_ID: '7idpc79ied61j2j4fsd0otkp87',
    COGNITO_DOMAIN:
      'https://fishsale-local.auth.ap-northeast-1.amazoncognito.com',
    COGNITO_REDIRECT_URI: 'http://localhost:5959/signin',
    // API_GO_ENDPOINT: 'http://localhost:1130',
    API_GO_ENDPOINT: 'https://dev.api.meatsale.jp',
    SITE_TYPE: 'meat',
    ENVCODE: envcode,
    TITLE: _utils.getName(envcode),
    WEB_APP_HOST: 'http://localhost:1110',
  };
};

export default local();
