import React, {useState, Fragment} from 'react';

import {Table, Button, Modal} from '@/components';
import * as hooks from './hooks';
import {makeColumns, regions, regions2} from './Columns';
import utils from '@/lib/utils';

import './index.scss';

const env = utils.env.get();

export const ShippingCosts: React.FC = () => {
  const {loading, sizeCosts, updateMyData, error} = hooks.useShippingTable();
  const {isResetModal, setIsResetModal, resetData} = hooks.useResetModal();
  const {isUpdateModal, setIsUpdateModal, updateData} = hooks.useUpdateModal();
  const [showFareTable, setShowFareTable] = useState(false);

  return (
    <div className="a__shipping-costs-main">
      <div className="b__title c__after-title">
        <div className="b__title-container">
          <h2 className="title">元払い運賃設定</h2>
          <div className="b__title-tips">
            <p>
              <b>
                ※クール料金は自動で計算され決済時に付与されるので、運賃表には付与しないで下さい。
              </b>
              詳しくは
              <a
                href={`${env.WEB_APP_HOST}/introduction/prepayment`}
                rel="noopener noreferrer"
                target="_blank"
              >
                こちら
              </a>
              <br />
              <b>
                ※離島中継料金（佐川急便）は自動で計算され決済時に付与されるので、運賃表には付与しないで下さい。
              </b>
              詳しくは
              <a
                href={`${env.WEB_APP_HOST}/fee`}
                rel="noopener noreferrer"
                target="_blank"
              >
                こちら
              </a>
              <br />
              ※税込金額を記入して下さい。
              <br />
              ※使用しない料金欄には、0を入力して保存して下さい。
              <br />
              ※元払い出品の送料については
              <a
                href={`${env.WEB_APP_HOST}/introduction/prepayment`}
                rel="noopener noreferrer"
                target="_blank"
              >
                こちら
              </a>
              から
              <br />
              ※運賃は、保険等のオプションによっても費用が変わります。その点を考慮して、運賃設定を行って下さい。
            </p>
          </div>
          <p className="error">{error}</p>
        </div>
        <div>
          <Button.Block
            color="main"
            onClick={() => {
              setShowFareTable(true);
            }}
            style={{whiteSpace: 'nowrap', width: 120}}
          >
            運賃表を表示
          </Button.Block>
          <Button.Block
            color="main"
            onClick={() => {
              setIsUpdateModal(true);
            }}
            disabled={loading || sizeCosts.length === 0}
          >
            保存
          </Button.Block>
          <Button.Block
            color="delete"
            onClick={() => {
              setIsResetModal(true);
            }}
            disabled={loading || sizeCosts.length === 0}
          >
            リセット
          </Button.Block>
        </div>
      </div>

      <div className="b__sizes-costs">
        <Table.Main
          columns={makeColumns(updateMyData)}
          data={sizeCosts}
          loading={loading}
          canUseSortBy={false}
        />
      </div>

      <Modal.Confirm
        isModal={isResetModal}
        setIsModal={setIsResetModal}
        title="データをリセット"
        confirmText="リセット"
        message="保存されていないデータをリセットします。よろしいですか？"
        onSubmit={resetData}
      />

      <Modal.Confirm
        isModal={isUpdateModal}
        setIsModal={setIsUpdateModal}
        title="データを保存"
        confirmText="保存"
        message="データを保存します。よろしいですか？"
        onSubmit={updateData}
        disabled={!!error}
      />

      <Modal.Normal
        handleCloseModal={() => {
          setShowFareTable(false);
        }}
        isModal={showFareTable}
        size="lg"
        submitColor="default"
        submitText=""
        title="運賃表"
      >
        <table className="a__fare-table">
          <thead className="b__thead">
            <tr className="b__tr">
              <th className="b__th">サイズ</th>
              {regions2.map(({region}) => (
                <th className="b__th" key={region}>
                  {regions[region]}
                </th>
              ))}
            </tr>
            <tr className="b__tr">
              <th className="b__th" />
              {regions2.map(({prefectures, region}) => (
                <th className="b__th" key={region}>
                  {(prefectures as readonly string[]).map((prefecture) => (
                    <Fragment key={prefecture}>
                      {prefecture}
                      <br />
                    </Fragment>
                  ))}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sizeCosts.map(({size, ...sizeCost}) => (
              <tr key={size}>
                <td className="b__td">{size}</td>
                {regions2.map(({region}) => (
                  <td className="b__td" key={region}>
                    {parseInt(sizeCost[region], 10).toLocaleString()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Normal>
    </div>
  );
};
