import React from 'react';
import {Field, FormikProps, FormikValues, ErrorMessage} from 'formik';

import Dropzone from '../Dropzone';

import {Input, Label, Dropdown, SemanticTextArea} from '@/components/Form';

// OPTIONS
import {
  shippingExpenses,
  deliveryMethods,
  shippingTimings,
  shippingSizes,
  areas,
  closeBidMins,
  featureYears,
  months,
  days,
} from '@/config/constants/options';
import {MeatCategory, MeatInputs} from '@/modules/meat/public/index';
import {ConversionMeatCategory} from '@/modules/meat/utils/conversion-category';

import './index.scss';

interface Props {
  formProps: FormikProps<FormikValues>;
  exhibit?: boolean;
  isCompany: boolean;
  shippingProvider: number;
  cost: {id: number; userId: number};
}

const Form: React.FC<Props> = ({
  exhibit,
  formProps,
  cost,
  isCompany,
  shippingProvider,
}) => {
  const {values} = formProps;

  return (
    <form className="b__stocks-detail__form-main">
      <Dropzone name="imageJson" disabled={exhibit} />
      <div className="b__field">
        <Label text="商品名 (最大50文字)" required />
        <Field
          name="name"
          component={Input.Default}
          maxLength="50"
          placeholder=""
          disabled={exhibit}
        />
        {formProps.errors['name'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="name" />
          </a>
        ) : null}
      </div>

      {/* 追加 */}
      <MeatCategory
        onChange={(name, val) => {
          formProps.setFieldValue(name, val);
        }}
        disabled={exhibit}
      />

      <MeatInputs
        formValues={formProps.values}
        errors={formProps.errors}
        category={
          ConversionMeatCategory(formProps.values['category'], 'en') as any
        }
        onChange={(name, val) => {
          formProps.setFieldValue(name, val);
        }}
        disabled={exhibit}
      />
      {/* ここまで追加 */}

      <div className="b__field">
        <Label text="配送料の負担" required />
        <Field
          name="shippingExpense"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingExpenses.filter(
            ({value}) =>
              value !== 3 ||
              (isCompany &&
                shippingProvider !== 0 &&
                cost.id !== 0 &&
                cost.userId !== 0)
          )}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingExpense'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingExpense" />
          </a>
        ) : null}
      </div>

      {formProps.values.shippingExpense === 3 ? (
        <div className="b__field">
          <Label text="配送サイズ区分" required />
          <Field
            name="shippingSize"
            component={Dropdown}
            disabled={exhibit}
            fluid
            placeholder="選択してください"
            selection
            options={shippingSizes}
            onChange={(_: any, field: any) => {
              formProps.setFieldValue(field.name, field.value);
            }}
          />
          {formProps.errors['shippingSize'] !== undefined ? (
            <a className="form__error">
              <ErrorMessage name="shippingSize" />
            </a>
          ) : null}
        </div>
      ) : null}

      <div className="b__field">
        <Label text="配送方法" required />
        <Field
          name="deliveryMethod"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={deliveryMethods}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['deliveryMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="deliveryMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送までの日数" required />
        <Field
          name="shippingTiming"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingTimings}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingTiming'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingTiming" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送元の地域" required />
        <Field
          name="area"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={areas}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['area'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="area" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品時の開始価格" required />
        <Field
          name="startPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['startPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="startPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="即決価格" optional />
        <Field
          name="fixPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['fixPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="fixPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品期限" required />
        <Field
          name="closeBidMin"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={closeBidMins}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['closeBidMin'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="closeBidMin" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品商品説明" required />
        <Field
          name="explanation"
          component={SemanticTextArea}
          rows="5"
          disabled={exhibit}
          maxLength="1000"
        />
        <div>(最大1,000文字)</div>
        {formProps.errors['explanation'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="explanation" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text={`繰り返し回数（出品した回数 ${values.count}）`} />
        <Field
          name="capacity"
          component={Input.Default}
          maxLength="10"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '回'}}
          labelPosition="right"
        />
        {formProps.errors['capacity'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="capacity" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="繰り返し期限" />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.year"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={featureYears}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.month"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.day"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>日</span>
          </div>
        </div>
        {formProps.errors['repeatLimitDate'] !== undefined ? (
          <a className="form__error">{formProps.errors.repeatLimitDate}</a>
        ) : null}
      </div>
    </form>
  );
};

export default Form;
