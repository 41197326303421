import {Dropdown, Label} from '@/components/Form';
import {MEAT_CATEGORY} from '../config';
import {Field} from 'formik';

interface Props {
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}

const MeatCategory = ({disabled, onChange}: Props) => {
  return (
    <>
      <Label text="種類" required />
      <Field
        name="category"
        component={Dropdown}
        fluid
        options={MEAT_CATEGORY}
        selection
        placeholder="選択してください"
        onChange={(_: any, field: any) => {
          onChange(field.name, field.value);
        }}
        {...{disabled}}
      />
    </>
  );
};

export default MeatCategory;
